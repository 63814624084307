<template>
  <div class="app-main">
    <h3>
      Sudoku Solver
      <img @click="helpClicked" height="20" src="./assets/help.png" alt="">
    </h3>
    <div class="main-box" :style="mainBoxStyling" ref="mainBox">
      <div class="cell-box" v-for="(c, ci) in 81" :key="ci" :style="cellStyling"
        @click="cellClicked(ci)" :ref="`cell${ci}`">{{ values[ci] }}</div>
        <!-- {{ values[ci] }} -->

      <div v-if="currentCell" :style="floatRowStyling" class="float-row selected-box"></div>
      <div v-if="currentCell" :style="floatColStyling" class="float-col selected-box"></div>
      <div v-if="currentCell" :style="floatBoxStyling" class="float-col selected-box"></div>

      <div class="main-box-border main-box-border-top"></div>
      <div class="main-box-border main-box-border-bottom"></div>
      <div class="main-box-border main-box-border-left"></div>
      <div class="main-box-border main-box-border-right"></div>

      <div class="main-box-border main-box-border-top" :style="borderMidTopStyling"></div>
      <div class="main-box-border main-box-border-top" :style="borderMidBottomStyling"></div>
      <div class="main-box-border main-box-border-left" :style="borderMidLeftStyling"></div>
      <div class="main-box-border main-box-border-left" :style="borderMidRightStyling"></div>
    </div>
    
    <div class="number-container">
      <button class="number-button" :style="buttonStyling" v-for="(i, k) in 9" :key="k"
        :disabled="buttonDisabled" @click="valueClicked">{{ i }}</button>
    </div>
    <div class="number-container">
      <button @click="clearValue" :disabled="clearDisabled">Clear</button>
      <button @click="solveClicked(isSolved ? 'Unsolve' : 'Solve')">{{ isSolved ? 'Unsolve' : 'Solve' }}</button>
      <button @click="resetClicked">Reset</button>
    </div>

    <div v-if="isSolved">{{ solvedMessage }}</div>

    <div v-if="isSolving" class="loader">
      <div class="load-animation"></div>
    </div>

    <div v-if="showHelp" :style="helpStyling" class="help" ref="helpContainer">
      How to use<br>
      1. Fill in the sudoku below<br>
      2. Click solve button<br><br>

      Click 'Unsolve' to clear the solution<br>
      Click 'Reset' to clear all the values<br>
      Click 'Clear' to remove the value for the selected cell
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {
  },
  data: function() {
    return {
      pageHeight: 0,
      pageWidth: 0,

      values: Array(81).fill(''),
      startValue: [],
      // NORMAL
      // values: ['5', '3', '', '', '7', '', '', '', '', '6', '', '', '1', '9', '5', '', '', '', '', '9', '8', '', '', '', '', '6', '', '8', '', '', '', '6', '', '', '', '3', '4', '', '', '8', '', '3', '', '', '1', '7', '', '', '', '2', '', '', '', '6', '', '6', '', '', '', '', '2', '8', '', '', '', '', '4', '1', '9', '', '', '5', '', '', '', '', '8', '', '', '7', '9'],
      // HARD
      // values: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '3', '', '8', '5', '', '', '1', '', '2', '', '', '', '', '', '', '', '5', '', '7', '', '', '', '', '', '4', '', '', '', '1', '', '', '', '9', '', '', '', '', '', '', '', '5', '', '', '', '', '', '', '7', '3', '', '', '2', '', '1', '', '', '', '', '', '', '', '', '4', '', '', '', '9'],

      currentCell: null,
      currentIndex: null,
      mainBox: null,

      buttonDisabled: true,
      clearDisabled: true,

      isSolving: null,
      isSolved: false,
      solvedMessage: '',

      cellPossibleValues: [],
      testedValues: [],

      mainBoxStyling: {
        minHeight: '',
        maxHeight: '',
        minWidth: '',
        maxWidth: ''
      },
      cellStyling: {
        minHeight: '',
        maxHeight: '',
        minWidth: '',
        maxWidth: ''
      },
      borderMidTopStyling: {
        top: ''
      },
      borderMidBottomStyling: {
        top: ''
      },
      borderMidLeftStyling: {
        left: ''
      },
      borderMidRightStyling: {
        left: ''
      },
      buttonStyling: {
        minHeight: '',
        maxHeight: '',
        minWidth: '',
        maxWidth: ''
      },

      floatRowStyling: {
        minHeight: '',
        maxHeight: '',
        top: '',
      },
      floatColStyling: {
        minWidth: '',
        maxWidth: '',
        left: '',
      },
      floatBoxStyling: {
        minHeight: '',
        maxHeight: '',
        minWidth: '',
        maxWidth: '',
        top: '',
        left: ''
      },

      allNumber: [
        '1', '2', '3', '4', '5', '6', '7', '8', '9'
      ],
      currentEmpty: null,

      showHelp: false,
      helpStyling: {
      },

      testIndex: 0,
      emptyIndex: [],
    }
  },
  props: {
  },
  methods: {
    cellClicked: function(index) {
      if (this.currentCell) {
        this.currentCell.classList.remove('selected');
        this.currentCell = null;

        if (this.currentIndex == index) {
          this.currentIndex = null;
          this.buttonDisabled = true;
          return;
        }
      }

      if (this.values[index]) {
        this.buttonDisabled = true;
        this.clearDisabled = false;
      } else {
        this.buttonDisabled = false;
        this.clearDisabled = true;
      }
      
      this.currentIndex = index;
      this.currentCell = this.$refs[`cell${index}`][0];
      this.currentCell.classList.add('selected');
      
      let row = Math.floor(index / 9);
      let col = index % 9;
      
      let rowValues = this.getRowValues(row);
      let colValues = this.getColValues(col);
      let boxValues = this.getBoxValues(index, row, col);

      this.highlightRowColumnBox();
      
      let valuesCombined = rowValues.concat(colValues).concat(boxValues);
      valuesCombined = valuesCombined.filter(v => v > 0);
      valuesCombined = [...new Set(valuesCombined)];

      this.cellPossibleValues = this.allNumber.filter(a => !valuesCombined.includes(a));
    },
    getRowValues: function(row) {
      let values = [];
      for (var i = row * 9; i < ((row + 1) * 9); i++) {
        values.push(this.values[i]);
      }
      return values;
    },
    getColValues: function(col) {
      let values = [];
      for (var i = col; i < 81; i += 9) {
        values.push(this.values[i]);
      }
      return values;
    },
    getBoxValues: function(index, row, col) {
      let rowPos = row % 3;
      let colPos = col % 3;

      let startIndex = (index - colPos) - (rowPos * 9);

      let values = [];
      for (let i = 0; i < 3; i++) {
        for (var j = 0; j < 3; j++) {
          values.push(this.values[startIndex]);
          startIndex++;
        }
        startIndex += 6;
      }

      return values;
    },

    solveCell: function(value, tryNError) {
      if (value) {
        let correctValue = this.cellPossibleValues.includes(value);

        if (correctValue) {
          this.$set(this.values, this.currentIndex, value);
          return true;
        } else {
          return false;
        }
      } else {
        this.$set(this.values, this.currentIndex, this.cellPossibleValues[0]);
        // if (this.cellPossibleValues.length == 1) {
        //   this.$set(this.values, this.currentIndex, this.cellPossibleValues[0]);
        // } else if (tryNError) {
        //   // console.log(this.cellPossibleValues);
        //   // let 
        //   this.$set(this.values, this.currentIndex, this.cellPossibleValues[0]);
        // }
      }
      // console.log(this.currentIndex);
    },

    checkTested: function(index) {
      this.cellClicked(index);
      let hasTested = this.testedValues.find(t => {
        return t['index'] == index;
      });
      console.log(index, hasTested['tested']);

      let possibleValues = this.cellPossibleValues.filter(p => !hasTested['tested'].includes(p));

      if (possibleValues.length > 0) {
        hasTested['tested'].push(possibleValues[0]);
      }
      return possibleValues;
    },

    valueClicked: function(e) {
      let value = e.target.innerHTML;

      this.solveCell(value);
      
      this.buttonDisabled = true;
      this.clearDisabled = false;
    },
    clearValue: function() {
      this.$set(this.values, this.currentIndex, '');
      this.buttonDisabled = false;
      this.clearDisabled = true;
    },

    trySolve: function(dir) {
      let direction = dir;
      let success, error;

      let prom = new Promise((res, rej) => {
        success = res;
        error = rej;
      });

      let worker = new Worker('webworker/worker.js');
      worker.onmessage = (e) => {
        success({ data: e.data, dir: dir });
      }

      prom.cancel = (reason) => {
        worker.terminate();
        error(reason + ' for ' + dir);
      }

      prom.start = (all) => {
        worker.postMessage({ direction: direction, values: all });
      }

      return prom;
    },

    solveClicked: async function(text) {
      if (text == 'Solve') {
        this.values.forEach(v => this.startValue.push(v));
        this.isSolving = true;
        let now = new Date().getTime();

        let allWorker = [];
        allWorker.push(this.trySolve('asc'));
        allWorker.push(this.trySolve('desc'));

        allWorker.forEach(w => {
          w.start(this.values);
          w.then(result => {
            this.values = result.data;

            allWorker.forEach(a => a.cancel('Cancel'));

            this.isSolving = false;
            this.isSolved = true;
            this.solvedMessage = `Solved in ${new Date().getTime() - now}ms`;
          }).catch(error => {
            console.log(error);
          });
        });
      } else {
        this.values = this.startValue;
        this.startValue = [];
        this.isSolved = false;
      }
    },
    resetClicked: function() {
      this.values = Array(81).fill('');
      this.isSolved = false;
    },

    sleep: function(ms) {
      let timeout;
      let reject;

      let prom = new Promise((res, rej) => {
        reject = rej;
        timeout = setTimeout(() => {
          res();
        }, ms);
      });
      prom.abort = () => {
        clearTimeout(timeout);
        reject('Sleep Cancelled');
      };

      return prom;
    },

    highlightRowColumnBox: function() {
      let cellRect = this.currentCell.getBoundingClientRect();
      let mainBoxRect = this.mainBox.getBoundingClientRect();

      this.floatRowStyling['minHeight'] = `${cellRect.height - 1}px`;
      this.floatRowStyling['maxHeight'] = `${cellRect.height - 1}px`;
      this.floatRowStyling['top'] = `${cellRect.top - mainBoxRect.top - 1}px`;

      this.floatColStyling['minWidth'] = `${cellRect.width - 1}px`;
      this.floatColStyling['maxWidth'] = `${cellRect.width - 1}px`;
      this.floatColStyling['left'] = `${cellRect.left - mainBoxRect.left - 1}px`;

      let midTopLeftPos = cellRect.height * 3;
      let midBottomRightPos = cellRect.height * 6;

      let boxTop = cellRect.top - mainBoxRect.top - 1;
      let boxLeft = cellRect.left - mainBoxRect.left - 1;

      if (boxTop < midTopLeftPos) { boxTop = 0; }
      else if (boxTop < midBottomRightPos) { boxTop = midTopLeftPos; }
      else { boxTop = midBottomRightPos; }

      if (boxLeft < midTopLeftPos) { boxLeft = 0; }
      else if (boxLeft < midBottomRightPos) { boxLeft = midTopLeftPos; }
      else { boxLeft = midBottomRightPos; }

      this.floatBoxStyling['minHeight'] = `${(cellRect.height * 3) - 1}px`;
      this.floatBoxStyling['maxHeight'] = `${(cellRect.height * 3) - 1}px`;
      this.floatBoxStyling['minWidth'] = `${(cellRect.width * 3) - 1}px`;
      this.floatBoxStyling['maxWidth'] = `${(cellRect.width * 3) - 1}px`;
      this.floatBoxStyling['top'] = `${boxTop}px`;
      this.floatBoxStyling['left'] = `${boxLeft}px`;
    },
    helpClicked: function(e) {
      this.showHelp = !this.showHelp;

      if (this.showHelp) {
        let rect = e.target.getBoundingClientRect();
        
        this.helpStyling['top'] = `${rect.top + rect.height}px`;
        this.helpStyling['left'] = `${this.pageWidth <= 425 ? rect.left / 2 : rect.left}px`;
      }

    },

    pageResize: function(e) {
      this.pageHeight = e.target.innerHeight;
      this.pageWidth = e.target.innerWidth;

      this.createBox();
    },
    onKeyDown: function(e) {
      if (this.currentIndex >= 0) {
        let number = parseInt(e.key);
        if (!Number.isNaN(number) && !this.values[this.currentIndex]) {
          this.valueClicked({ target: { innerHTML: e.key } });
        } else if (e.key == 'Backspace' || e.key == 'Delete') {
          this.clearValue();
        } else if (e.key == 'Tab') {
          let index = this.currentIndex;
          this.cellClicked(++index);
          e.preventDefault();
        } else if (e.key == 'ArrowUp' || e.key == 'ArrowDown' || e.key == 'ArrowLeft' || e.key == 'ArrowRight') {
          e.preventDefault();
          let index = this.currentIndex;
          switch (e.key) {
            case 'ArrowUp':
              index -= 9;
              if (index >= 0) {
                this.cellClicked(index);
              }
              break;
            case 'ArrowDown':
              index += 9;
              if (index <= 80) {
                this.cellClicked(index);
              }
              break;
            case 'ArrowLeft':
              index--;
              if (index % 9 < 8 && index >= 0) {
                this.cellClicked(index);
              }
              break;
            case 'ArrowRight':
              index++;
              if (index % 9 > 0) {
                this.cellClicked(index);
              }
              break;
          }
        }
      }
    },
    createBox: function() {
      let size = this.pageHeight < this.pageWidth ? this.pageHeight * 0.8 : this.pageWidth * 0.8;

      Object.keys(this.mainBoxStyling).forEach(k => {
        this.mainBoxStyling[k] = `${size}px`;
      });
      Object.keys(this.cellStyling).forEach(k => {
        this.cellStyling[k] = `${size / 9 - 1}px`;
      });
      Object.keys(this.buttonStyling).forEach(k => {
        let buttonSize = size / 14;
        if (buttonSize < 20) { buttonSize = 20 };
        this.buttonStyling[k] = `${buttonSize}px`;
      });

      this.borderMidTopStyling['top'] = `${size / 9 * 3 - 1}px`;
      this.borderMidBottomStyling['top'] = `${size / 9 * 6 - 1}px`;
      this.borderMidLeftStyling['left'] = `${size / 9 * 3 - 1}px`;
      this.borderMidRightStyling['left'] = `${size / 9 * 6 - 1}px`;

      this.mainBox = this.$refs['mainBox'];

      setTimeout(() => {
        if (this.currentCell) {
          this.highlightRowColumnBox();
        }
      }, 10);
    },
  },
  mounted() {
    this.pageHeight = window.innerHeight;
    this.pageWidth = window.innerWidth;
    window.addEventListener('resize', this.pageResize);
    window.addEventListener('keydown', this.onKeyDown);
    this.createBox();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.pageResize);
    window.removeEventListener('keydown', this.onKeyDown);
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h3 {
    display: flex;
    align-items: center;

    > img {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  > .main-box {
    border-color: black;
    border-width: 1px 0 0 1px;
    border-style: solid;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    > .main-box-border {
      position: absolute;
      background-color: black;
    }

    > .main-box-border-top, > .main-box-border-bottom {
      height: 2px;
      width: 100%;
    }

    > .main-box-border-left, > .main-box-border-right {
      width: 2px;
      height: 100%;
    }

    > .main-box-border-top {
      left: 0;
      top: -1px;
    }

    > .main-box-border-bottom {
      left: 0;
      bottom: 1px;
    }

    > .main-box-border-left {
      left: -1px;
      top: 0;
    }

    > .main-box-border-right {
      right: -1px;
      top: 0;
    }

    > .cell-box {
      // background-color: rgba(255,255,0,0.75);
      border-color: black;
      border-width: 0 1px 1px 0;
      border-style: solid;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    > .float-row, > .float-col {
      position: absolute;
      pointer-events: none;
      z-index: -100;
    }

    > .float-row {
      width: calc(100% - 2px);
      left: 1px;
    }

    > .float-col {
      height: calc(100% - 2px);
      top: 1px;
    }
  }

  > .number-container {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
    justify-content: center;

    > .number-button {
      cursor: pointer;
      border-radius: 100%;
      margin: 5px;
      padding: 0;
    }

    > button {
      margin: 0 2px;
    }
  }

  > .loader {
    z-index: 999;
    background-color: rgba(0,0,0,0.3);
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    > .load-animation {
      height: 10vh;
      width: 10vh;
      background-color: transparent;
      border: 2px solid blue;
      animation: spin linear 1s infinite;
    }
  }

  > .help {
    position: absolute;
    padding: 10px;
    background: rgb(60,60,60);
    color: white;
    border-radius: 5px;
  }
}

.inner-boxes {
  border: 1px solid black;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
}

.selected {
  -webkit-box-shadow: inset 0 0 5px 2px rgb(88, 88, 255);
  -moz-box-shadow: inset 0 0 5px 2px rgb(88, 88, 255);
  box-shadow: inset 0 0 5px 2px rgb(88, 88, 255);
}

.selected-box {
  -webkit-box-shadow: inset 0 0 5px 2px gold;
  -moz-box-shadow: inset 0 0 5px 2px gold;
  box-shadow: inset 0 0 5px 2px gold;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<style>
body {
  margin: 0;
}
</style>